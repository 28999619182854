import momentTimezone from 'moment-timezone'

export const timezones = momentTimezone.tz.names().map(tz => {
  const offset = momentTimezone.tz(tz).utcOffset()
  const formattedOffset = `(GMT${offset >= 0 ? '+' : ''}${(offset / 60)
    .toFixed(2)
    .replace('.00', '')})`

  return {
    name: `${tz} ${formattedOffset}`,
    id: tz
  }
})
