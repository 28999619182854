<template>
  <div class="page" id="call-bridge-list">
    <div class="mb-2 p-3 card header">
      <h2 class="m-0">
        {{ $t('call-bridge.call-create.title') }}
      </h2>
    </div>

    <div class="content card p-3">
      <Field
        :label="$t('call-bridge.call-create.form.flow.label')"
        :info="$t('call-bridge.call-create.form.flow.info')"
        :error="errors.conferenceBridgeFlow"
      >
        <Select
          :options="flows"
          v-model="model.conferenceBridgeFlow"
          :allowEmpty="false"
          searchable
          @search="fetchAllFlows"
        >
        </Select>
      </Field>

      <div class="row mb-2">
        <div class="col-12 col-md-6">
          <Field
            :label="$t('call-bridge.call-create.form.firstCalleeName.label')"
            :info="$t('call-bridge.call-create.form.firstCalleeName.info')"
            :error="errors['firstRecipient.name']"
          >
            <b-form-input
              class="border"
              :placeholder="
                $t('call-bridge.call-create.form.firstCalleeName.placeholder')
              "
              v-model="model.firstRecipient.name"
            />
          </Field>
        </div>
        <div class="col-12 col-md-6">
          <Field
            :label="$t('call-bridge.call-create.form.firstCalleeNumber.label')"
            :info="$t('call-bridge.call-create.form.firstCalleeNumber.info')"
            :error="errors['firstRecipient.phone']"
          >
            <b-form-input
              class="border"
              :placeholder="
                $t('call-bridge.call-create.form.firstCalleeNumber.placeholder')
              "
              v-model="model.firstRecipient.phone"
              @keydown="onlyAcceptNumbers"
            />
          </Field>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12 col-md-6">
          <Field
            :label="$t('call-bridge.call-create.form.secondCalleeName.label')"
            :info="$t('call-bridge.call-create.form.secondCalleeName.info')"
            :error="errors['secondRecipient.name']"
          >
            <b-form-input
              class="border"
              :placeholder="
                $t('call-bridge.call-create.form.secondCalleeName.placeholder')
              "
              v-model="model.secondRecipient.name"
            />
          </Field>
        </div>
        <div class="col-12 col-md-6">
          <Field
            :label="$t('call-bridge.call-create.form.secondCalleeNumber.label')"
            :info="$t('call-bridge.call-create.form.secondCalleeNumber.info')"
            :error="errors['secondRecipient.phone']"
          >
            <b-form-input
              class="border"
              :placeholder="
                $t(
                  'call-bridge.call-create.form.secondCalleeNumber.placeholder'
                )
              "
              v-model="model.secondRecipient.phone"
              @keydown="onlyAcceptNumbers"
            />
          </Field>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12 col-md-6">
          <Field
            :label="$t('call-bridge.call-create.form.timezone.label')"
            :info="$t('call-bridge.call-create.form.timezone.info')"
            :error="errors.timezone"
          >
            <Select
              :options="timezones"
              v-model="model.timezone"
              :searchable="true"
              :allowEmpty="false"
            >
            </Select>
          </Field>
        </div>
        <div class="col-12 col-md-6">
          <Field
            :label="$t('call-bridge.call-create.form.duration.label')"
            :info="$t('call-bridge.call-create.form.duration.info')"
            :error="errors.duration"
          >
            <b-form-input
              class="border"
              :placeholder="
                $t('call-bridge.call-create.form.duration.placeholder')
              "
              v-model="model.duration"
              type="number"
              min="1"
              @keydown="onlyAcceptNumbers"
            />
          </Field>
        </div>
        <div class="col-12 col-md-6">
          <Field
            :label="$t('call-bridge.call-create.form.datetime.label')"
            :info="$t('call-bridge.call-create.form.datetime.info')"
            :error="errors.dateTime"
            class="d-flex flex-column"
          >
            <date-picker
              class="d-flex"
              v-model="model.dateTime"
              :config="{
                mode: 'single',
                minDate: 'today',
                enableTime: true,
                dateFormat: 'Y-m-d h:i K'
              }"
            />
          </Field>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <b-button
          variant="primary"
          class="mb-2 px-5 py-3"
          @click="createCall"
          :disabled="createLoading"
        >
          {{ $t('call-bridge.call-create.form.create') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Field from '../../../elements/Field.vue'
import DatePicker from '../../../elements/DatePicker.vue'
import { onlyAcceptNumbers } from '../../../utils/utils'
import CallBridgesService from '../../../services/callBridge.service'
import { timezones } from '../../../constants/timezones.js'
import { object, string, date, number } from 'yup'
import { yupToKV } from '../../../utils/yup'
import momentTimezone from 'moment-timezone'
import { isValidPhoneNumber } from '../../../utils/phone'
import moment from 'moment'

export default {
  name: 'CreateCall',
  components: {
    Field,
    DatePicker
  },
  computed: {
    timezones() {
      return timezones
    },
    timezone() {
      let timezone = momentTimezone.tz.guess()
      const regex = new RegExp('^[a-zA-Z_]+\\/[a-zA-Z_]+$')
      if (!regex.test(timezone)) {
        timezone = 'Asia/Riyadh'
      }
      return timezones.find(tz => tz.id === timezone)
    },
    schema() {
      return object().shape({
        conferenceBridgeFlow: string()
          .trim()
          .required(this.$t('call-bridge.call-create.form.flow.required')),
        firstRecipient: object().shape({
          name: string()
            .trim()
            .required(
              this.$t('call-bridge.call-create.form.firstCalleeName.required')
            ),
          phone: string()
            .trim()
            .test(
              'isValidPhone',
              this.$t('call-bridge.call-create.form.firstCalleeNumber.invalid'),
              isValidPhoneNumber
            )
            .notOneOf(
              [this.model.secondRecipient.phone],
              this.$t(
                'call-bridge.call-create.form.firstCalleeNumber.different'
              )
            )
            .required(
              this.$t('call-bridge.call-create.form.firstCalleeNumber.required')
            )
        }),
        secondRecipient: object().shape({
          name: string()
            .trim()
            .required(
              this.$t('call-bridge.call-create.form.secondCalleeName.required')
            ),
          phone: string()
            .trim()
            .test(
              'isValidPhone',
              this.$t(
                'call-bridge.call-create.form.secondCalleeNumber.invalid'
              ),
              isValidPhoneNumber
            )
            .notOneOf(
              [this.model.firstRecipient.phone],
              this.$t(
                'call-bridge.call-create.form.secondCalleeNumber.different'
              )
            )
            .required(
              this.$t(
                'call-bridge.call-create.form.secondCalleeNumber.required'
              )
            )
        }),
        duration: number()
          .required(this.$t('call-bridge.call-create.form.duration.required'))
          .min(
            1,
            this.$t('call-bridge.call-create.form.duration.min', { min: 1 })
          ),
        dateTime: string()
          .required(this.$t('call-bridge.call-create.form.datetime.required'))
          .test(
            'is-future',
            this.$t('call-bridge.call-create.form.datetime.future'),
            function(value) {
              const timezone = this.parent.timezone
              const now = moment.tz(timezone)
              const dateTime = moment.tz(
                moment(value).format('YYYY-MM-DD HH:mm'),
                'YYYY-MM-DD HH:mm',
                true,
                timezone
              )
              return dateTime.isAfter(now)
            }
          ),
        timezone: string()
          .trim()
          .required(this.$t('call-bridge.call-create.form.timezone.required'))
      })
    }
  },
  data() {
    return {
      model: {
        conferenceBridgeFlow: {},
        firstRecipient: {
          name: '',
          phone: ''
        },
        secondRecipient: {
          name: '',
          phone: ''
        },
        duration: '1',
        dateTime: moment().format('YYYY-MM-DD HH:mm'),
        timezone: ''
      },
      createLoading: false,
      flows: [],
      errors: {}
    }
  },
  methods: {
    onlyAcceptNumbers,
    async fetchAllFlows(name) {
      try {
        this.createLoading = true
        const res = await CallBridgesService.listAllCallBridges({ name }, 1, 10)
        const { flows, ...pagination } = res.data
        this.flows = flows.map(flow => ({
          name: flow.name,
          id: flow.id
        }))
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message

        console.log(errorMessage)
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.createLoading = false
      }
    },
    async createCall() {
      try {
        this.errors = {}
        const data = {
          ...this.model,
          conferenceBridgeFlow: this.model.conferenceBridgeFlow.id,
          timezone: this.model.timezone ? this.model.timezone.id : '',
          duration: parseInt(this.model.duration),
          dateTime: moment(
            this.model.dateTime
              .toString()
              .replace('م', 'PM')
              .replace('ص', 'AM')
          ).format('YYYY-MM-DD HH:mm')
        }

        await this.schema.validate(data, { abortEarly: false }).catch(err => {
          this.errors = yupToKV(err)
        })

        if (!this.schema.isValidSync(data)) {
          return
        }
        const res = await CallBridgesService.createBridgeCall(data)
        this.toast(res.message)
        this.$router.push('/call-bridge/calls')
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, { type: 'error' })
        console.log(error)
      }
    }
  },
  mounted() {
    this.fetchAllFlows()
    this.model.timezone = this.timezone
    console.log(this.model)
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
</style>
